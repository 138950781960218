import React from "react"
import styles from "../styles/components/white-box.module.scss"

import classNames from "classnames/bind"
const cx = classNames.bind(styles)

const WhiteBox = props => {
  return (
    <div className={cx("container", { shadowLarge: props.shadowLarge })}>
      {props.children}
    </div>
  )
}

export default WhiteBox
