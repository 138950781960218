import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WhiteBox from "../../components/whiteBox"
import BulletList from "../../components/bulletList"

import styles from "../../styles/pages/berenson-capital.module.scss"
import pageStyles from "../../styles/pages/page-styles.module.scss"

const WhiteBoxContent = ({ title, subtext }) => (
  <div className={styles.boxContainer}>
    <WhiteBox>
      <div className={styles.boxContentContainer}>
        <div className={styles.boxTitle}>{title}</div>
        <p className={styles.boxSubtext}>{subtext}</p>
      </div>
    </WhiteBox>
  </div>
)

const BerensonCapital = ({ location, data }) => {
  var page = data.contentfulCapitalFocusPage
  var boxes = page.fourBoxesSection
  return (
    <Layout location={location.pathname}>
      <SEO title="Berenson Capital" />
      {/*<BackgroundLines yPosition={370} />*/}
      <div className={pageStyles.pageContentContainer}>
        <h2 className={pageStyles.pageTitle}>Focus</h2>
        <div className={styles.contentSection}>
          <h3 className={styles.subTitle}>{page.listSectionTitle}</h3>
          <p className={styles.subText}>
            {page.listSectionSubtitle.listSectionSubtitle}
          </p>
          <div className={styles.contentFlexContainer}>
            <div className={styles.contentFlexItem}>
              <div className={styles.bulletListMargin}>
                <BulletList>
                  {page.listSection.map((item, key) => {
                    if (!item.additionalItem) {
                      return (
                        <li key={key}>
                          {/*Need to use divs to contain multiple items within <li>*/}
                          <div>
                            <h4>{item.listItemTitle1}</h4>
                            <p>{item.listItemSubtitle1.listItemSubtitle1}</p>
                          </div>
                        </li>
                      )
                    } else {
                      return (
                        <li key={key}>
                          <div className={styles.bulletItemFlexContainer}>
                            <div>
                              <h4>{item.listItemTitle1}</h4>
                              <p>{item.listItemSubtitle1.listItemSubtitle1}</p>
                            </div>
                            <div>
                              <h4>{item.listItemTitle2}</h4>
                              <p>{item.listItemSubtitle2.listItemSubtitle2}</p>
                            </div>
                          </div>
                        </li>
                      )
                    }
                  })}
                </BulletList>
              </div>
            </div>
            <div className={styles.contentFlexItem}>
              {/*<p className={styles.subTitle}>{page.pageHeading.pageHeading}</p>*/}
              <div className={styles.boxesColContainer}>
                <div className={styles.boxesRowContainer}>
                  <WhiteBoxContent
                    title={boxes[0].boxTitle}
                    subtext={boxes[0].boxSubtext.boxSubtext}
                  />
                  <WhiteBoxContent
                    title={boxes[1].boxTitle}
                    subtext={boxes[1].boxSubtext.boxSubtext}
                  />
                </div>
                <div className={styles.boxesRowContainer}>
                  <WhiteBoxContent
                    title={boxes[2].boxTitle}
                    subtext={boxes[2].boxSubtext.boxSubtext}
                  />
                  <WhiteBoxContent
                    title={boxes[3].boxTitle}
                    subtext={boxes[3].boxSubtext.boxSubtext}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BerensonCapital

export const query = graphql`
  query InvestmentCriteriaPage {
    contentfulCapitalFocusPage {
      fourBoxesSection {
        boxTitle
        boxSubtext {
          boxSubtext
        }
      }
      listSectionTitle
      listSectionSubtitle {
        listSectionSubtitle
      }
      listSection {
        listItemTitle1
        listItemSubtitle1 {
          listItemSubtitle1
        }
        additionalItem
        listItemTitle2
        listItemSubtitle2 {
          listItemSubtitle2
        }
      }
    }
  }
`
