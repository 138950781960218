import React from "react"
import styles from "../styles/components/bullet-list.module.scss"
import { css } from "@emotion/core"

class BulletList extends React.Component {
  constructor(props) {
    super(props)
    //heightAdjust is used to shorten the dotted line connecting the bullets
    this.state = {
      heightAdjust: 0,
    }
    this.checkHeightAdjust = this.checkHeightAdjust.bind(this)
  }

  componentDidMount() {
    this.checkHeightAdjust()
    window.addEventListener("resize", () => this.checkHeightAdjust)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.checkHeightAdjust)
  }

  checkHeightAdjust() {
    var heightAdjust =
      document.getElementById("bulletList").lastChild.offsetHeight + 10
    if (heightAdjust !== this.state.heightAdjust) {
      this.setState({ heightAdjust: heightAdjust })
    }
  }

  render() {
    const height = css`
      &:before {
        height: calc(100% - ${this.state.heightAdjust}px);
      }
    `
    return (
      <ul className={styles.bulletList} css={height} id="bulletList">
        {this.props.children}
      </ul>
    )
  }
}

export default BulletList
